import { createRoot } from 'react-dom/client';
import React, { VFC, useState, useCallback, useEffect, useMemo } from "react"
import { css } from "@emotion/react"
import { AppProvider } from "../components"

const FAQ_URL =
  'https://sixtypercent.notion.site/BRAND-FAQ-MAIN-01ce7c93d67b464cabeffbdc73b19330'

const helpButtonStyle = css`
  cursor: pointer;
  opacity: 1;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  bottom: 16px;
  right: 16px;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  font-size: 20px;
  box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 2px 4px;
`

const App: VFC = () => {
  return (
    <AppProvider lang={'en'}>
      <HelpButton />
    </AppProvider>
  )
}

const HelpButton: VFC = () => {
  return (
    <div css={helpButtonStyle} onClick={() => window.open(FAQ_URL, '_blank')}>?</div>
  )
}

const root = createRoot(document.getElementById("help_button_root")!);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
